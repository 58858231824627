<template>
    <pagination :title="title" :entities="users" :filterAction="filterAction" headerColourClass="bg-transparent">
        <template v-slot:title>
            <div class="flex justify-between items-center">
                <span>{{ title }}</span>
                <div v-if="title === 'Attendance'" class="mr-2">
                    <a v-if="hasLink(links, 'oss-daily-report')" href="/hr-attendance" type="button" class="rounded-lg px-2 py-1 font-semibold shadow-sm bg-v3-gray-50 mx-1" :class="{'text-v3-gray-600 bg-opacity-10 dark:text-v3-gray-400': !isActive('/hr-attendance'), 'text-v3-gray-800 bg-opacity-50 dark:text-v3-gray-200': isActive('/hr-attendance')}">Daily Report </a>
                    <a v-if="hasLink(links, 'oss-weekly-report')" href="/hr-weekly" type="button" class="rounded-lg px-2 py-1 font-semibold shadow-sm bg-v3-gray-50 mx-1" :class="{'text-v3-gray-600 bg-opacity-10 dark:text-v3-gray-400': !isActive('/hr-weekly'), 'text-v3-gray-800 bg-opacity-50 dark:text-v3-gray-200': isActive('/hr-weekly')}">Weekly Report </a>
                </div>
            </div>
        </template>
        <template v-slot:headers>
            <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold sm:pl-6">Name
            </th>
            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold">{{parent.properties.get('day1')}}</th>
            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold">{{parent.properties.get('day2')}}</th>
            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold">{{parent.properties.get('day3')}}</th>
            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold">{{parent.properties.get('day4')}}</th>
            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold">{{parent.properties.get('day5')}}</th>
            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold">{{parent.properties.get('day6')}}</th>
            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold">{{parent.properties.get('day7')}}</th>
            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold">Total (h)</th>
            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold">Billable Total (£)</th>
        </template>
        <template v-slot:items>
            <tr v-for="user in users">
                <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium sm:pl-6">
                    {{user.entity.properties.get('user')}}
                </td>
                <td class="whitespace-nowrap px-3 py-4 text-sm">
                    {{user.entity.properties.get('day1')}}
                </td>
                <td class="whitespace-nowrap px-3 py-4 text-sm">
                    {{user.entity.properties.get('day2')}}
                </td>
                <td class="whitespace-nowrap px-3 py-4 text-sm">
                    {{user.entity.properties.get('day3')}}
                </td>
                <td class="whitespace-nowrap px-3 py-4 text-sm">
                    {{user.entity.properties.get('day4')}}
                </td>
                <td class="whitespace-nowrap px-3 py-4 text-sm">
                    {{user.entity.properties.get('day5')}}
                </td>
                <td class="whitespace-nowrap px-3 py-4 text-sm">
                    {{user.entity.properties.get('day6')}}
                </td>
                <td class="whitespace-nowrap px-3 py-4 text-sm">
                    {{user.entity.properties.get('day7')}}
                </td>
                <td class="whitespace-nowrap px-3 py-4 text-sm">
                    {{user.entity.properties.get('weekly_total')}}
                </td>
                <td class="whitespace-nowrap px-3 py-4 text-sm">
                    {{user.entity.properties.get('billable_total')}}
                </td>
            </tr>
            <tr>
                <td class="whitespace-nowrap px-3 py-4 text-sm text-right" :colspan="8">Grand Total (h):</td>
                <td class="whitespace-nowrap px-3 py-4 text-sm">{{getWeeklyHoursTotal()}}</td>
                <td class="whitespace-nowrap px-3 py-4 text-sm"></td>
            </tr>
            <tr>
                <td class="whitespace-nowrap px-3 py-4 text-sm text-right" :colspan="8">Grand Billable Total:</td>
                <td class="whitespace-nowrap px-3 py-4 text-sm"></td>
                <td class="whitespace-nowrap px-3 py-4 text-sm">{{getBillableTotal()}}</td>
            </tr>
        </template>
    </pagination>
</template>
<script>
import Pagination from "@/v3/components/pagination/Pagination.vue";
import dayjs from "dayjs";
import LocationViewerWithIcon from "@/components/LocationViewerWithIcon.vue";
import PhotoViewerWithIcon from "@/components/PhotoViewerWithIcon.vue";

export default {
  name: 'HrAttendanceWeeklyPagination',
    components: {PhotoViewerWithIcon, LocationViewerWithIcon, Pagination},
  props: {
    users: {},
      parent:{
          type: Object,
          default: null
      },
      links:{
          type: Object,
          default:null
    },
      title:{
        type: String,
          default: 'Attendance'
      }
  },
    computed:{
        filterAction(){
            if(!this.parent){
                return null;
            }
            return this.parent.actions.filter(action => action.name === 'show-weekly-report').first();
        },
    },
    methods:{
        formatToTime(datetime){
            return dayjs(datetime).format('HH:mm');
        },
        hasLink(links,key){
            if(links) {
                return links.filter(link => link.rels.contains(key)).size;
            }
            return false;
        },
        isActive(key){
            return this.$route.path.includes(key)
        },
        getWeeklyHoursTotal(){
            return this.parent.properties.get('hours_total')
        },
        getBillableTotal(){
            return this.parent.properties.get('billable_total')
        },
    }
}
</script>
